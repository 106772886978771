<template>
    <div class="container">
        <div class="row">
            <div class="col-xs-6 portfolio-item">
                <div class="card">
                    <a href="#portfolioModal1" class="portfolio-link" data-toggle="modal">
                        <div class="caption">
                            <div class="caption-content">
                                <h1>SANAT</h1>
                            </div>
                        </div>
                        <img src="https://firebasestorage.googleapis.com/v0/b/dilasokmen-d4c96.appspot.com/o/814%20kopya.webp?alt=media&token=98d34915-fef0-4916-80c8-45e7cb442ee1"
                            class="img-responsive" alt="">

                    </a>
                </div>
            </div>
            <div class="col-xs-6 portfolio-item">
                <a href="#portfolioModal2" class="portfolio-link" data-toggle="modal">
                    <div class="caption">
                        <div class="caption-content">
                            <h1>TASARIM</h1>
                            
                        </div>
                    </div>
                    <img src="https://firebasestorage.googleapis.com/v0/b/dilasokmen-d4c96.appspot.com/o/ss2024B%20kopya.webp?alt=media&token=5fc4df60-0e4e-475e-ae58-bfb0e900a52c"
                        class="img-responsive" alt="">
                </a>
            </div>
           <!--  <div class="col-xs-6 portfolio-item">
                <a href="#portfolioModal3" class="portfolio-link" data-toggle="modal">
                    <div class="caption">
                        <div class="caption-content">
                            <i class="fa fa-search-plus fa-3x"></i>
                            <br>
                            VESTEL RETRO
                        </div>
                    </div>
                    <img src="https://firebasestorage.googleapis.com/v0/b/dilasokmen-d4c96.appspot.com/o/vestel.webp?alt=media&token=fd7915dd-9b8a-43d2-828e-55cf749018d5"
                        class="img-responsive" alt="">
                </a>
            </div> -->
        </div>
      
    </div>
</template>