<template>    
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2>HAKKINDA</h2>
                    <hr class="star-light">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 ">
                    <p>{{ hakkimda }}</p>
                </div>       

            </div>
        </div>
</template>
<script>

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseApp = {
    apiKey: "AIzaSyD7Fw5h09yrs43jM1U-bbkqa_zeoyYNRdk",
    authDomain: "dilasokmen-d4c96.firebaseapp.com",
    databaseURL: "https://dilasokmen-d4c96-default-rtdb.firebaseio.com",
    projectId: "dilasokmen-d4c96",
    storageBucket: "dilasokmen-d4c96.appspot.com",
    messagingSenderId: "459592365584",
    appId: "1:459592365584:web:1e30d73b9958b82413bc95",
    measurementId: "G-QT2P546D5E"
};

firebase.initializeApp(firebaseApp);


export default {


    data() {
        return {
            hakkimda: '',
        };
    },
    mounted() {
        var database = firebase.database();
        var ref = database.ref('/projects');
        ref.orderByChild('fk').equalTo('hakkimda').on('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                var childData = childSnapshot.val();
                this.hakkimda = childData.hakkimda;
            });
        });
    },
}
</script>