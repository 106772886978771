<template>
  <form @submit.prevent="editingKey ? updateProject() : submitForm()" class="mb-3">
    <h1>Proje Ekle</h1>
    <div class="mb-3">
      <label class="form-label">
        Giriş:</label>
      <select v-model="giris" class="form-select">
        <option disabled value="">Lütfen bir giriş seçin</option>
        <option>Sanat</option>
        <option>Tasarım</option>
      </select>
    </div>
    <div class="mb-3">
      <label class="form-label">
        Proje Adı:
        <input v-model="projeAdi" class="form-control" type="text" required>
      </label>
    </div>
    <div class="mb-3">
      <label class="form-label">
        Medya Türü:
        <select v-model="selectedMedia" class="form-control">
          <option disabled value="">Lütfen bir tür seçin</option>
          <option>Resim</option>
          <option>Video</option>
        </select>
      </label>
    </div>

    <div class="mb-3" v-if="selectedMedia === 'Resim'">
      <label class="form-label">
        Resim URL:
        <input v-model="resimUrl" class="form-control" type="text" required>
      </label>
    </div>

    <div class="mb-3" v-else-if="selectedMedia === 'Video'">
      <label class="form-label">
        Video URL:
        <input v-model="videoUrl" class="form-control" type="text" required>
      </label>
    </div>
    <div class="mb-3">
      <label class="form-label">
        Açıklama:
        <textarea v-model="aciklama" class="form-control" required></textarea>
      </label>
    </div>
    <div class="mb-3">
      <label class="form-label">
        Zaman:
        <input v-model="zaman" class="form-control" type="text" required>
      </label>
    </div>
    <div class="mb-3">
      <label class="form-label">
        Konum:
        <input v-model="konum" class="form-control" type="text" required>
      </label>
    </div>
    <button type="submit" class="btn btn-primary">Gönder</button>
  </form>
  <div class="container">
    <table class="table">
      <thead>
        <tr>
          <th>Giriş</th>
          <th>Proje Adı</th>
          <th>Resim URL</th>
          <th>Video URL</th>
          <th>Açıklama</th>
          <th>Zaman</th>
          <th>Konum</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(project, key) in projects" :key="key">
          <td>{{ project.giris }}</td>
          <td>{{ project.projeAdi }}</td>
          <td><img :src="project.resimUrl" alt="Proje Resmi" class="img-thumbnail" style="width: 100px;"></td>
          <td><iframe width="560" height="315" :src="project.videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></td>
          <td>{{ project.aciklama }}</td>
          <td>{{ project.zaman }}</td>
          <td>{{ project.konum }}</td>
          <td>
            <button @click="editProject(project)">Düzenle</button>
            <button @click="deleteProject(project)">Sil</button>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</template>
<style>
iframe {
  width: 200px;
  height: auto;
}
</style>



<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { getDatabase, ref, onValue, push, update, remove } from 'firebase/database';




const firebaseApp = {
  apiKey: "AIzaSyD7Fw5h09yrs43jM1U-bbkqa_zeoyYNRdk",
  authDomain: "dilasokmen-d4c96.firebaseapp.com",
  databaseURL: "https://dilasokmen-d4c96-default-rtdb.firebaseio.com",
  projectId: "dilasokmen-d4c96",
  storageBucket: "dilasokmen-d4c96.appspot.com",
  messagingSenderId: "459592365584",
  appId: "1:459592365584:web:1e30d73b9958b82413bc95",
  measurementId: "G-QT2P546D5E"
};
firebase.initializeApp(firebaseApp);
export default {
  data() {
    return {
      giris: '',
      projeAdi: '',
      resimUrl: '',
      aciklama: '',
      zaman: '',
      konum: '',
      projects: [],
      editingKey: null,
      selectedMedia: '',
      videoUrl: '',
    };
  },
  methods: {
   
    resetForm() {
      this.giris = '';
      this.projeAdi = '';
      this.resimUrl = '';
      this.aciklama = '';
      this.zaman = '';
      this.konum = '';
      this.selectedMedia = '';
      this.videoUrl = '';
    },
    async submitForm() {

      const data = {
        giris: this.giris,
        projeAdi: this.projeAdi,
        aciklama: this.aciklama,
        zaman: this.zaman,
        konum: this.konum,
        selectedMedia: this.selectedMedia,
        videoUrl: this.videoUrl,
        resimUrl: this.resimUrl,
      };

      const db = getDatabase();
      try {
        await push(ref(db, 'projects'), data);
        // Veri başarıyla eklendi, formu temizle
        this.resetForm();
      } catch (error) {
        console.error("Veri eklenirken hata oluştu: ", error);
      }

      this.$store.commit('setData', data);
    },
    editProject(project) {
      // Form alanlarını seçili projenin verileri ile doldur

      this.resimUrl = project.resimUrl;
      this.videoUrl = project.videoUrl;
      this.giris = project.giris;
      this.projeAdi = project.projeAdi;
      this.aciklama = project.aciklama;
      this.zaman = project.zaman;
      this.konum = project.konum;

      // Düzenlenen projenin anahtarını sakla
      this.editingKey = project.key;
      console.log(this.editingKey);
    },
    async updateProject() {

      const data = {
        giris: this.giris,
        projeAdi: this.projeAdi,
        aciklama: this.aciklama,
        zaman: this.zaman,
        konum: this.konum,
        selectedMedia: this.selectedMedia,
        videoUrl: this.videoUrl,
        resimUrl: this.resimUrl,
      };
      const db = getDatabase();
      try {
        await update(ref(db, 'projects/' + this.editingKey), data);
        this.resetForm();
        this.editingKey = null;
      } catch (error) {
        console.error("Veri güncellenirken hata oluştu: ", error);
      }
    },
    async deleteProject(project) {
      const db = getDatabase();
      try {
        await remove(ref(db, 'projects/' + project.key));
      } catch (error) {
        console.error("Veri silinirken hata oluştu: ", error);
      }
    },
  },
  created() {

    const db = getDatabase();
    const projectsRef = ref(db, 'projects');

    onValue(projectsRef, (snapshot) => {
      const val = snapshot.val();
      if (val) {
        this.projects = Object.keys(val).map(key => ({ key, ...val[key] }));
        // Filtreleme işlemi
        this.projects = this.projects.filter(project => project.giris === 'Tasarım' || project.giris === 'Sanat');
        } else {
        this.projects = [];
      }
    });

  },


};
</script>