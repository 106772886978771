import { createStore } from 'vuex'


export default createStore({
  state: {
    data: null,
  },
  getters: {
  },
  mutations: {
    setData(state, data) {
      state.data = data;
    },
  },
  actions: {
  },
  modules: {
  }
})
