import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, onValue } from 'firebase/database';

const firebaseApp = initializeApp({
    apiKey: "AIzaSyD7Fw5h09yrs43jM1U-bbkqa_zeoyYNRdk",
    authDomain: "dilasokmen-d4c96.firebaseapp.com",
    databaseURL: "https://dilasokmen-d4c96-default-rtdb.firebaseio.com",
    projectId: "dilasokmen-d4c96",
    storageBucket: "dilasokmen-d4c96.appspot.com",
    messagingSenderId: "459592365584",
    appId: "1:459592365584:web:1e30d73b9958b82413bc95",
    measurementId: "G-QT2P546D5E"
});

const db = getDatabase(firebaseApp);

const data = store.state.data;

push(ref(db, 'projects'),data);

onValue(ref(db, 'projects'), (snapshot) => {
    store.commit('setData', snapshot.val());
});

createApp(App).use(store).use(router).mount('#app')