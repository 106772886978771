<template>
    <form @submit.prevent="submitForm" class="mb-3">
        <h1>Hakkımda Düzenle</h1>
        <div class="mb-3">
            <label class="form-label">
                Hakkımda:
                <textarea v-model="hakkimda" class="form-control" type="text" required></textarea>
            </label>
        </div>
        <div class="mb-3">
            <label class="form-label">
                Profil Resim URL:
                <input v-model="p_resimUrl" class="form-control" type="text" required>
            </label>
        </div>
        <button type="submit" class="btn btn-primary" @click="updateProject">Gönder</button>

    </form>
    <div class="container">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Hakkımda</th>
                    <th scope="col">Resim URL</th>
                    <th scope="col">editingKey</th>
                    <th scope="col">Düzenle</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(project, index) in projects" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ project.hakkimda }}</td>
                    <td>{{ project.p_resimUrl }}</td>
                    <td>{{ project.editingKey }}</td>
                    <td>
                        <button @click="editProject(project.key)">Düzenle</button>
                    </td>
                </tr>
              
            </tbody>
        </table>
    </div>
</template>
  
<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { getDatabase, ref, onValue, update } from 'firebase/database';

// ...



const firebaseApp = {
    apiKey: "AIzaSyD7Fw5h09yrs43jM1U-bbkqa_zeoyYNRdk",
    authDomain: "dilasokmen-d4c96.firebaseapp.com",
    databaseURL: "https://dilasokmen-d4c96-default-rtdb.firebaseio.com",
    projectId: "dilasokmen-d4c96",
    storageBucket: "dilasokmen-d4c96.appspot.com",
    messagingSenderId: "459592365584",
    appId: "1:459592365584:web:1e30d73b9958b82413bc95",
    measurementId: "G-QT2P546D5E"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseApp);
}



export default {
    data() {
        return {
            fk: 'hakkimda',
            hakkimda: '',
            p_resimUrl: '',
            projects: [],
            editingKey: null,
            email: '',
            password: '',
        };
    },

    mounted() {
        this.fetchProjects();
    },
    methods: {
        async fetchProjects() {
            const db = getDatabase();
            const projectsRef = ref(db, 'projects');
            onValue(projectsRef, (snapshot) => {
                const val = snapshot.val();
                if (val) {
                    this.projects = Object.keys(val).map(key => ({ key, ...val[key] }));
                    // Filtreleme işlemi
                    this.projects = this.projects.filter(project => project.fk === 'hakkimda');
                } else {
                    this.projects = [];
                }
            });
        },
        editProject(key) {
            const project = this.projects.find(project => project.key === key);
            if (project) {
                this.hakkimda = project.hakkimda;
                this.p_resimUrl = project.p_resimUrl;
                this.editingKey = key;
            }
        },
        async updateProject() {
            if (this.editingKey !== null) {
                const db = getDatabase();
                const projectRef = ref(db, 'projects/' + this.editingKey);
                await update(projectRef, {
                    hakkimda: this.hakkimda,
                    p_resimUrl: this.p_resimUrl
                });
                this.fetchProjects();
            }
        },
        
    },
};
</script>