import {createWebHistory, createRouter} from 'vue-router'
import ArtAdd from './components/ArtAdd.vue'
import AboutAdd from './components/AboutAdd.vue'
import Home from './components/HomeComp.vue'
const routes = [
  { path: '/about-add', component: AboutAdd },
  { path: '/art-add', component: ArtAdd },
  { path: '/', component: Home },
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})



export default router