<template>
    <div id="app">
        <!-- Navigation -->
        <nav id="mainNav" class="navbar navbar-default navbar-fixed-top navbar-custom">
            <div class="container">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="navbar-header page-scroll">
                    <button type="button" class="navbar-toggle" data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1">
                        <span class="sr-only">Toggle navigation</span> Menu <i class="fa fa-bars"></i>
                    </button>
                    <a class="navbar-brand" href="#page-top">DİLA SÖKMEN</a>
                </div>

                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul class="nav navbar-nav navbar-right">
                        <li class="hidden">
                            <a href="#page-top"></a>
                        </li>
                        
                        <li class="page-scroll">
                            <a href="#about">HAKKINDA</a>
                        </li>
                    </ul>
                </div>
                <!-- /.navbar-collapse -->
            </div>
            <!-- /.container-fluid -->
        </nav>

        <!-- Header -->
<!--       <header>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <img class="img-responsive" :src="p_resimUrl" alt="">
                        <div class="intro-text">
                            <span class="name">Dila Sökmen</span>
                            <hr class="star-light">
                            <span class="skills">Sanatçı - Tasarımcı</span>
                        </div>
                    </div>
                </div>
            </div>
        </header> -->

        <!-- Portfolio Grid Section -->
        <section id="portfolio">
            <portfolioComp></portfolioComp>
        </section>
        <!-- About Section -->
        <section id="about" class="success">
            <aboutComp></aboutComp>

        </section>

        <!-- Footer -->
        <footer class="text-center">
            <div class="footer-above">
                <div class="container">
                    <div class="row">
                        <div class="footer-col col-md-4">

                        </div>
                        <div class="footer-col col-md-4">
                            <h3>Takip Edin</h3>
                            <ul class="list-inline">
                               
                                <li>
                                    <a href="https://www.instagram.com/dilasokmen/" class="btn-social btn-outline"><i class="fa fa-fw fa-instagram"></i></a>
                                </li>
                                <li>
                                    <a href="https://tr.linkedin.com/in/dilas%C3%B6kmen" class="btn-social btn-outline"><i class="fa fa-fw fa-linkedin"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div class="footer-col col-md-4">

                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-below">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <a href="https://soysalsokmen.com/"><i class="">Soysta 2024</i></a>
                                
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <!-- Scroll to Top Button (Only visible on small and extra-small screen sizes) -->
        <div class="scroll-top page-scroll hidden-sm hidden-xs hidden-lg hidden-md">
            <a class="btn btn-primary" href="#page-top">
                <i class="fa fa-chevron-up"></i>
            </a>
        </div>

        <!-- Portfolio Modals -->
        <artComp></artComp>
        <designComp></designComp>

    </div>
</template>

<script>
import AboutComp from "./AboutComp.vue";
import PortfolioComp from "./PortfolioComp.vue";
import ArtComp from "./ArtComp.vue";
import DesignComp from "./DesignComp.vue";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseApp = {
    apiKey: "AIzaSyD7Fw5h09yrs43jM1U-bbkqa_zeoyYNRdk",
    authDomain: "dilasokmen-d4c96.firebaseapp.com",
    databaseURL: "https://dilasokmen-d4c96-default-rtdb.firebaseio.com",
    projectId: "dilasokmen-d4c96",
    storageBucket: "dilasokmen-d4c96.appspot.com",
    messagingSenderId: "459592365584",
    appId: "1:459592365584:web:1e30d73b9958b82413bc95",
    measurementId: "G-QT2P546D5E"
};

firebase.initializeApp(firebaseApp);


export default {

    components: {
        aboutComp: AboutComp,
        portfolioComp: PortfolioComp,
        artComp: ArtComp,
        designComp: DesignComp,

    },

    data() {
        return {
            p_resimUrl: '',
        };
    },
    mounted() {
        var database = firebase.database();
        var ref = database.ref('/projects');
        ref.orderByChild('fk').equalTo('hakkimda').on('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                var childData = childSnapshot.val();
                this.p_resimUrl = childData.p_resimUrl;
            });
        });
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
